import React from 'react';
import { NavigationDots, SocialMedia } from '../components';

const AppWrap = (Component: () => JSX.Element, idName: string, classNames?: string) => function HOC() {
    return (
        <div id={idName} className={`app__container ${classNames}`}>
          { idName !== 'contact' && <SocialMedia />}
          <div className="app__wrapper app__flex">
            <Component />
          </div>
          <NavigationDots active={idName} />
        </div>
      );
};

export default AppWrap;